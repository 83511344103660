import React from 'react';
import './style.scss';

const Contact = () => {
  return (
    <div className='container contact-container'>
        Email: info@homelychef.com
        Phone: 8299849412
        Address: Gaur city 1 , greater noida west Gautam Buddha Nagar UTTAR PRADESH 201309
    </div>
  )
}

export default Contact