import React from "react";
import Heading from "../containers/Heading";
import "../styles/privacy.scss";

const Terms = () => {
  return (
    <div className="terms-container container">
      <h3> Terms & Conditions </h3>
      <Heading component={"h4"} content={"Last updated on Jan 10th 2025"} />
      <p>
        {" "}
        For the purpose of these Terms and Conditions, The term "we", "us",
        "our" used anywhere on this page shall mean Homelychef. "you", “your”,
        "user", “visitor” shall mean any natural or legal person who is visiting
        our website and/or agreed to purchase from us.
      </p>
      <Heading
        component={"h4"}
        content={
          "Your use of the website and/or purchase from us are governed by following Terms and Conditions:"
        }
      />

      <ul>
        <li>
          The content of the pages of this website is subject to change without
          notice.
        </li>
        <li>
          Neither we nor any third parties provide any warranty or guarantee as
          to the accuracy, timeliness, performance, completeness or suitability
          of the information and materials found or offered on this website for
          any particular purpose. You acknowledge that such information and
          materials may contain inaccuracies or errors and we expressly exclude
          liability for any such inaccuracies or errors to the fullest extent
          permitted by law.
        </li>
        <li>
          {" "}
          Reproduction is prohibited other than in accordance with the copyright
          notice, which forms part of these terms and conditions.
        </li>

        <li>
          {" "}
          Your use of any information or materials on our website and/or product
          pages is entirely at your own risk, for which we shall not be liable.
          It shall be your own responsibility to ensure that any products,
          services or information available through our website and/or product
          pages meet your specific requirements.
        </li>
        <li>
          In case of any dispute in transcation customer can raise the concern
          through the email info@homelychef.com .
        </li>
        <li>
          {" "}
          You may not create a link to our website from another website or
          document without Homelychef’s prior written consent.{" "}
        </li>
        <li>
          {" "}
          Unauthorized use of information provided by us shall give rise to a
          claim for damages and/or be a criminal offense.{" "}
        </li>
        <li>
          {" "}
          We, shall be under no liability whatsoever in respect of any loss or
          damage arising directly or indirectly out of the decline of
          authorization for any Transaction, on Account of the Cardholder having
          exceeded the preset limit mutually agreed by us with our acquiring
          bank from time to time
        </li>
        <li>
          The Customer agrees and acknowledges that the use of the services
          offered by HomelyChef is at the sole risk of the Customer. HomelyChef
          disclaims all representations and warranties of any kind, whether
          express or implied as to condition, suitability, quality,
          merchantability and fitness of the services offered by HomelyChef. The
          liability of HomelyChef is excluded to the fullest extent permitted by
          law.
        </li>
        <li>
          {" "}
          Any dispute arising out of use of our website and/or purchase with us
          and/or any engagement with us is subject to the laws of India .
        </li>
        <li>The pricing rates are subject to change in the future.</li>
      </ul>
    </div>
  );
};

export default Terms;
